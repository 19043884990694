import React, { Suspense, lazy, useState } from 'react';

// Dependencies.
import { useTranslation } from 'react-i18next';
import { Switch, Route, Redirect } from 'react-router-dom';
import axios from 'axios';

// Context.
import { AuthContext } from './context/auth';

// Modules executed.
import PrivateRoute from './routes/PrivateRoute';
import { apiURL, themes } from './shared/theme';
import LoadingIcon from './components/LoadingIcon/LoadingIcon';
import { AlertFire } from './components/AlertFire/AlertFire';

// Page.
const LoginPage = lazy(() => import('./pages/Login'));
const RegisterPage = lazy(() => import('./pages/Register'));
const RedirectCode = lazy(() => import('./pages/SignUp/RedirectCode'));
const ProfilePage = lazy(() => import('./pages/Profile'));
const CashPage = lazy(() => import('./pages/Cash'));
const DepositPage = lazy(() => import('./pages/Deposit'));
const WithdrawPage = lazy(() => import('./pages/Withdraw'));
const ProductPage = lazy(() => import('./pages/Products'));
const PromotionPage = lazy(() => import('./pages/Promotion'));
const PlayerLogs = lazy(() => import('./pages/PlayerLogs'));
const ProcessPage = lazy(() => import('./pages/OrderProcess'));
const ChangePassword = lazy(() => import('./pages/ChangePassword'));
const Notification = lazy(() => import('./pages/Notification'));
const VerifyPage = lazy(() => import('./pages/Verify'));
const TSpin = lazy(() => import('./pages/TSpin'));

const loadingStyle = {
  color: themes.color.white,
  paddingTop: '20%'
};

function App () {

  const existingTokens = JSON.parse(localStorage.getItem('hiToken'));
  const { t } = useTranslation();
  const [ authTokens, setAuthTokens ] = useState(existingTokens);
  const [ loading, setLoading ] = useState(true);
  const [ orderData, setOrderData ] = useState({
    type: '',
    amount: 0
  });
  const [ me, setMe ] = useState({
    username: '',
    gen: '',
    id: '',
    credit: 0,
    point: 0,
    bankName: '',
    bankImage: '',
    bankAccount: '',
    bankNameAccount: '',
    products: [],
    line: '',
    email: '',
    birthday: ''
  });
  const [ haveOrder, setHaveOrder ] = useState(false);

  const setTokens = data => {
    localStorage.setItem('hiToken', JSON.stringify(data));
    setAuthTokens(data);
  }

  const destroyTokens = () => {
    localStorage.removeItem('hiToken');
    localStorage.removeItem('token');
    setLoading(true);
    setAuthTokens('');
  };

  const getProfile = async () => {
    try {
      const response = await axios.post( apiURL.authentication + '/post-verify', {},
      { headers: { 'authorization': authTokens } });
      if (response.data.statusCode === 98) {
        AlertFire(t('hint-deposit-maintenance'));
        setLoading(false);
        destroyTokens();
      } else {
        const responseData = response.data.user;
        const user = {
          username: responseData.username,
          gen: responseData.gen,
          id: responseData._id,
          credit: responseData.credit,
          point: responseData.point,
          bankName: responseData.bankName,
          bankImage: responseData.bankImg,
          bankAccount: responseData.bankAccount,
          bankNameAccount: responseData.bankNameAccount,
          products: responseData.webAccess,
          line: responseData.line,
          email: responseData.email,
          birthday: responseData.birthday
        }
        setMe(user);
        setLoading(false);
      }
    } catch (error) {
      AlertFire(t('hint-user-expires'));
      destroyTokens();
    }
  };

  const checkProcessOrder = async () => {
    try {
      const response = await axios.post(apiURL.provider + '/transaction-data/check-process', { token: authTokens });
      if (response.status !== 200) return;
      if (!response.data.order) return;
      setHaveOrder(response.data.isOrder);
      const responseOrder = response.data.order;
      for (let key in responseOrder) {
        if (responseOrder[key] !== null) {
          setOrderData(responseOrder[key]);
        }
      }
    } catch (error) {
      setLoading(true);
    }
  };

  return (<>
    <AuthContext.Provider value={{
        authTokens,
        getProfile,
        me,
        profileLoading: loading,
        setAuthTokens: setTokens,
        destroyAuthTokens: destroyTokens,
        getProcess: checkProcessOrder,
        haveOrder: haveOrder,
        orderProcess: orderData
        }}>
      <Suspense fallback={<div style={loadingStyle}><LoadingIcon /></div>}>
        <Switch>
          <Route path="/verify" component={VerifyPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/aff" component={RedirectCode} />
          <Route path="/aff=:code" component={RegisterPage} />
          <Route path="/register" component={RegisterPage} />
          <PrivateRoute path="/topspin" component={TSpin} />
          <PrivateRoute path="/recent-order" component={Notification} />
          <PrivateRoute path="/change-password" component={ChangePassword} />
				  <PrivateRoute path="/order/process" component={ProcessPage} />
				  <PrivateRoute path="/history" component={PlayerLogs} />
				  <PrivateRoute path="/promotions" component={PromotionPage} />
				  <PrivateRoute path="/products" component={ProductPage} />
				  <PrivateRoute path="/withdraw" component={WithdrawPage} />
				  <PrivateRoute path="/deposit" component={DepositPage} />
				  <PrivateRoute path="/profile" component={ProfilePage} />
				  <PrivateRoute exact path="/" component={CashPage} />
          <Redirect from='*' to='/' />
        </Switch>
      </Suspense>
    </AuthContext.Provider>
    </>);
}

export default App;