import Swal from 'sweetalert2';
import 'animate.css';
import { themes } from '../../shared/theme';

export const AlertFire = (title, icon = 'error') => {
  return Swal.fire({
    position: 'top',
    icon: icon,
    background: themes.color.darkGray,
    title: `<div style="color:${themes.color.white}; font-size: 18px;">${ title }</div>`,
    showClass: {
      popup: 'animated fadeInDown faster'
    },
    hideClass: {
      popup: 'animated fadeOutUp faster'
    },
    confirmButtonColor: themes.color.primary
  });
};