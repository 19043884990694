import React from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from '../../shared/theme';

const iconStyle = {
	margin: '0 auto',
	background: 'url("/icon_alltop.svg") no-repeat',
	backgroundSize: 'cover',
	width: '100px',
	height: '100px',
	overflow: 'hidden',
	padding: '12px 5px',
	borderRadius: '50%',
	textAlign: 'center'
};

const hintStyle = {
  fontSize: '20px',
	textAlign: 'center',
  color: themes.color.primary
};

function LoadingIcon () {
	const { t } = useTranslation();
  return (<>
    <div style={iconStyle}></div>
    <div className="mt-4" style={hintStyle}>
      <div>{t('loading-data')}</div>
    </div>
  </>);
}

export default LoadingIcon;