import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import Cookies from 'universal-cookie';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

// Google GA and GTM.
// import ReactGA from 'react-ga';
// import TagManager from 'react-gtm-module';

import './index.css';
import './animate.css';
import App from './App';

import common_en from "./translations/en/common.json";
import common_th from "./translations/th/common.json";
import common_cn from "./translations/cn/common.json";
import common_ms from "./translations/ms/common.json";

library.add(fas, far, fal, fab);

// ReactGA.initialize('UA-118401085-1');
// ReactGA.pageview(window.location.pathname + window.location.search);

// const tagManagerArgs = { gtmId: 'GTM-ND5X9GQ' };

// TagManager.initialize(tagManagerArgs);

const cookies = new Cookies();
const lng = cookies.get('lng');

i18next.init({
	interpolation: { escapeValue: false },
	lng: lng || 'th',
	fallbackLng: lng || 'th',
	resources: {
		en: { translation: common_en },
		th: { translation: common_th },
		cn: { translation: common_cn },
		ms: { translation: common_ms }
	}
});

ReactDOM.render(
	<I18nextProvider i18n={i18next}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</I18nextProvider>, document.getElementById('root'));

