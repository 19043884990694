
export const themes = {
	color: {
		primary: '#C58F35',
		second: '#B47A27',
		lightGray: '#797979',
		gray: '#5E616F',
		darkGray: '#545555',
		background: '#292E2E',
		dark: '#545555',
		softDark: '#3A3A3C',
		white: '#FFFFFF',
		success: '#3d9a07',
		danger: '#ff7072',
		softRed: '#d75c5c',
		darkCyan: '#6f717c'
	},
	alternative: 'AllTopAsia',
	logo: '/logo_alltop.png'
};

export const apiURL = {
	authentication: 'https://atas.hibizsoft.com/atas-client-auth',
	endpoint: 'https://atas.hibizsoft.com/client-data/api/hi-json/',
	transaction: 'https://atas.hibizsoft.com/client-order/api/hi-json/',
	provider: 'https://atas.hibizsoft.com/alltopasia'
};

export const currency = "THB";
export const countryCode = "th";